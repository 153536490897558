
import { Component, Vue } from 'vue-property-decorator'
import ResetPassword from '@/components/reset-password/TheResetPassword.vue'

@Component({
  components: {
    ResetPassword
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})
export default class ResetPasswordPage extends Vue {

}
