
import '@/assets/styles/imports/reset-password.sass'
import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'

@Component
export default class ResetPassword extends Vue {
  token = ''
  email = ''
  requiredEmail = false
  errorMessage = ''
  sendEmail = false
  password = ''
  requiredPassword = false
  confirmPassword = ''
  requiredConfirmPassword = false
  passwordNotMatch = false

  checkStartToken () {
    if (this.$route.query.token) this.token = `${this.$route.query.token}`
    if (this.$route.query.email) this.email = `${this.$route.query.email}`
  }

  sendForm () {
    if (!this.email.length) return this.setRequiredField('requiredEmail')
    this.fetchCheckEmail()
  }

  async fetchCheckEmail () {
    await api.authorize.forgotPassword(this.email)
    this.sendEmail = true
  }

  eventResetPassword () {
    if (this.comparisonPassword()) return
    this.resetPassword()
  }

  async resetPassword () {
    const reset = await api.authorize.resetPassword(this.email, this.token, this.password)
    if (reset) return this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang }})
    alert('Error')
  }

  setRequiredField (field: string) {
    this[field] = true
  }

  comparisonPassword () {
    return this.passwordNotMatch = this.password !== this.confirmPassword
  }

  created () {
    this.checkStartToken()
  }
}
